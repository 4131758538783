import React from "react"
import { Link } from "gatsby"

import App from "../components/app";
import SEO from "../components/seo"
import PageWrapper from "../components/_molecules/page-wrapper"

import "./404.scss"

const NotFoundPage = () => (
  <App>
    <div className="p-notfound__root">
      <SEO
        title="404: Not found"
        slug="/404"
      />
      <PageWrapper>
        <div className="p-notfound__main">
          <h1 className="p-notfound__header">
              Oh dear <span>:(</span>
          </h1>
          <p  className="p-notfound__text">You just hit a route that doesn&#39;t exist...
            <br/><Link to="/">Return to home</Link>
          </p>
        </div>
      </PageWrapper>
    </div>
  </App>
)

export default NotFoundPage
